@font-face {
  font-family: "BalooTammudu2";
  src: local("BalooTammudu2"),
    url(../fonts/Baloo_Tammudu_2/BalooTammudu2-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Capriola";
  src: local("Capriola"),
    url(../fonts/Capriola/Capriola-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Comfortaa";
  src: local("Comfortaa"),
    url(../fonts/Comfortaa/Comfortaa-Regular.ttf) format("truetype");
}

@import "~bootstrap/scss/bootstrap";

$Blue-Green: #00bbff;
$Radical-Red: #ff3864;
$Cultured: #f4f4f6;

html {
  font-size: 16px;
}

body {
  background-color: $Cultured;
  font-family: "Comfortaa";
  padding-top: 63px;
  min-width: 325px;
}

.bg-light {
  background-color: $Cultured !important;
}

.navbar-brand {
  font-size: 1.5em;
  font-family: "Capriola";
}

.container-fluid {
  padding: 0px;
}

.container {
  margin-top: 35px;
  margin-bottom: 35px;
}

.serviceHeading {
  margin-top: 30px;
  font-weight: bold;
  font-size: 1.5em;
  // color:$Radical-Red;
}

.flex-container {
  margin-left: 10%;
  margin-right: 10%;
}

.serviceDiv {
  max-width: 375px;
  width: auto;
  margin: 30px 30px;
  transition: linear 0.3s;
}

.serviceDiv p {
  font-size: 1.1em;
}

.serviceCircle {
  display: block;
  height: 225px;
  width: 225px;
  line-height: 225px;
  margin: auto;
  transition: linear 0.3s;
}

// .serviceDiv:hover{
//   border: 1px solid rgb(221, 221, 221);
//   border-radius: 10px;
//   box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.39);
//   padding:20px;
//   transform: scale(1.3);
//   background-color: white;
// }

.serviceDiv:hover .serviceCircle {
  box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.39);
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.serviceDiv:hover .serviceHeading {
  color: $Radical-Red;
}

.card {
  height: 425px;
  width: 300px;
  margin: 15px;
  border: none;
  box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.39);
}

.card:hover {
  box-shadow: none;
  border: 1px solid #ff59752c;
}

.card:hover .card-title {
  color: $Radical-Red;
  text-decoration: none;
}

.card-title {
  font-weight: bold;
  font-size: 1.1em;
}

.d-flex a {
  color: black;
  text-decoration: none;
}

.error {
  color: red;
  margin-top: 10px;
  display: block;
}

.section-title {
  font-size: calc(1.5em + 1vw);
  margin: auto;
  white-space: nowrap;
  // color: $Blue-Green;
  font-weight: bold;
  font-family: "BalooTammudu2";
  text-align: center;
  width: fit-content;
}

.section-title::after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 80%;
  border-bottom: 6px solid $Radical-Red;
}

.copyrightLogo {
  text-align: center;
  font-size: 12px;
  color: white;
  width: 100%;
  background-color: #373536;
  padding-top: 20px;
  padding-bottom: 20px;
}

@include media-breakpoint-down(lg) {
  .flex-container {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@include media-breakpoint-down(md) {
  .serviceDiv .serviceCircle {
    box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.39);
    -moz-border-radius: 50%;
    border-radius: 50%;
  }

  .serviceCircle {
    height: 162px;
    width: 162px;
    line-height: 162px;
  }

  .serviceDiv {
    max-width: 700px;
    margin: 10px 30px;
  }
}

@include media-breakpoint-down(sm) {
  .card {
    width: auto;
    margin: 10px 10px;
    height: auto;
  }

  .navbar-brand {
    font-size: 4.5vw;
  }

  body {
    padding-top: 57px;
  }
}

@include media-breakpoint-down(xs) {
}
